<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col-12">
				<li
					v-if="paymentMethods.length > 1"
					class="list-group-item border-0 rounded-0 pb-0">
					<div class="row">
						<div class="col-12">
							<div class="h4 mb-2">
								{{ translate('select_payment_method') }}
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div
								:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
								class="btn-group-lg payment-method"
								role="group">
								<b-button
									v-for="item in paymentMethods"
									:key="item.id"
									:variant="selectedPaymentMethodName === item.attributes.method_code_name ? 'primary' : 'light'"
									@click="selectedPaymentMethodName = item.attributes.method_code_name">
									{{ translate(item.attributes.method_code_name) }}
								</b-button>
							</div>
						</div>
					</div>
				</li>
				<form
					@submit.prevent="prepareForStorage()"
					@keydown="clearValidationError($event.target.name);"
					@change="handleAddressFormChange($event.target.name);">
					<ul
						:class="{ 'mx-3': showSectionsAsCards }"
						class="list-group">
						<li
							:class="{ 'border-0': !showSectionsAsCards }"
							class="list-group-item h-100 p-0">
							<div
								v-if="showSectionsAsCards"
								class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
								<div class="col my-auto align-middle">
									<div class="h5 m-0">
										{{ translate(selectedPaymentMethodName) }}
									</div>
								</div>
							</div>
							<div class="pb-2">
								<b-row>
									<b-col>
										<ul class="list-group w-100">
											<li class="list-group-item border-0 rounded-0 mt-1 pb-0">
												<credit-card-payment-register-v2
													v-if="isCardPaymentMethod"
													ref="creditCardV2Register"
													:show-title="paymentMethods.length === 1"
													:payment-method-conditions="conditions"
													:can-split-payment="canSplitPayment"
													:split-payment-import="splitPayment"
													:split-payment-amount-import="splitPaymentAmount"
													:cart-total="cartTotal"
													:form="form"
													@update:splitPayment="splitPayment = $event"
													@update:splitPaymentAgreement="splitPaymentAgreement = $event"
													@update:splitAmount="splitAmount = $event"
													@preparing="preparing = $event" />
												<!-- <template v-if="selectedPaymentMethodName === 'checkmo'">
													<div
														v-if="paymentMethods.length === 1"
														class="row">
														<div class="col-12">
															<div class="h4 my-2">
																{{ translate('cash_payment') }}
															</div>
														</div>
													</div>
													<div class="row m-3">
														<div class="col-12">
															<b-alert
																:show="!enabledPaymentMethods['checkmo']"
																variant="danger"
																v-html="translate('cash_payments_unavailable', {
																	url: $router.resolve({ name: 'Store' }).href,
																	min: (typeof paymentMethodConditions['checkmo'] !== 'undefined') ? paymentMethodConditions['checkmo'].min : '',
																})" />
															<template v-if="enabledPaymentMethods['checkmo']">
																<b-alert
																	show
																	variant="warning"
																	class="h5">
																	<b>{{ translate('important_note').toUpperCase() }}</b>: {{ translate('international_wires_only') }}
																</b-alert>
																<b-alert
																	show
																	variant="info"
																	class="text-normal"
																	v-html="translate('cash_payments_details', { displayedLink: companyEmail, supportLink: companyEmail })" />
																<b-alert
																	show
																	variant="warning">
																	{{ translate('cash_payments_disclaimer') }}
																</b-alert>
															</template>
														</div>
													</div>
												</template> -->
												<bitcoin-payment
													v-if="selectedPaymentMethodName === 'banktransfer'"
													:show-title="paymentMethods.length === 1"
													:payment-method-conditions="paymentMethodConditions['banktransfer'] || {}" />
												<sezzle-payment
													v-if="selectedPaymentMethodName === 'sezzle'"
													:show-title="paymentMethods.length === 1"
													:payment-method-conditions="paymentMethodConditions['sezzle'] || {}"
													sezzle-details="sezzle_details" />
												<oxxo-payment
													v-if="selectedPaymentMethodName === 'oxxopay'"
													:show-title="paymentMethods.length === 1"
													:payment-method-conditions="paymentMethodConditions['oxxopay'] || {}" />
												<g-cash-payment
													v-if="selectedPaymentMethodName === 'gcash'"
													:show-title="paymentMethods.length === 1"
													:payment-method-conditions="paymentMethodConditions['gcash'] || {}" />
												<pay-pal-payment
													v-if="selectedPaymentMethodName === 'lptpaypal'"
													:show-title="paymentMethods.length === 1"
													:payment-method-conditions="paymentMethodConditions['lptpaypal'] || {}"
													paypal-details="lptpaypal_details" />
											</li>
											<li class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pb-0">
												<div class="row">
													<div class="col-12">
														<div class="h4 mb-2">
															{{ translate('billing_address') }}
														</div>
													</div>
												</div>
												<div class="row">
													<div
														v-if="isCardPaymentMethod"
														class="col-12">
														<b-alert show>
															{{ translate('billing_disclaimer') }}
														</b-alert>
													</div>
													<div class="col-12 mb-3">
														<div class="custom-control custom-checkbox">
															<input
																id="customCheck1"
																v-model="sameAs"
																type="checkbox"
																class="custom-control-input">
															<label
																class="custom-control-label"
																for="customCheck1">{{ translate('same_as_shipping') }}</label>
														</div>
													</div>
												</div>
												<div v-if="!sameAs">
													<address-country-form
														:user-country="true"
														:form-import.sync="form"
														:errors-import="validationErrors"
														:prefix-errors="`${currentValidation}.billing.`"
														:country-code-import="country"
														:display-cancel-button="false"
														:display-submit-button="false"
														:display-goback-button="false" />
												</div>
											</li>
										</ul>
									</b-col>
								</b-row>
							</div>
						</li>
					</ul>
				</form>
			</div>
		</div>
		<li class="list-group-item border-0 rounded-0 pt-0 pb-3">
			<div class="row no-gutters justify-content-end">
				<!-- <div
					v-if="currentStep() !== 2"
					:class="{ 'pr-2': currentStep() !== 2 }"
					class="col">
					<b-button
						variant="secondary"
						size="lg"
						:style="!['xs'].includes(windowWidth) ? 'min-width: 150px;' : ''"
						:class="{ 'w-100': ['xs'].includes(windowWidth) }"
						class="float-md-right"
						@click="$emit('cancel')">
						{{ translate('cancel') }}
					</b-button>
				</div> -->
				<div
					:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
					:class="currentStep() !== 2 ? 'col-6' : 'col-12'"
					class="col col-md-3">
					<b-button
						:disabled="validating || !!preparing || !enabledPaymentMethods[selectedPaymentMethodName] || (splitPayment && !splitPaymentAgreement)"
						variant="primary"
						size="lg"
						:style="currentStep() !== 2 ? '' : 'min-width: 200px;'"
						class="w-100"
						@click="prepareForStorage()">
						<i
							v-if="validating || !!preparing"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
					</b-button>
				</div>
			</div>
		</li>
		<same-address-as-card-statement-modal
			v-model="showSameAddressAsCardStatementModal"
			:country-to-check="country"
			:card-type="selectedCardInfo.card_type"
			:card-type2="selectedCardInfo.card_type_2"
			:last-digits="selectedCardInfo.last_digits"
			:last-digits2="selectedCardInfo.last_digits_2"
			:address="selectedCardInfo.address"
			@confirm="savePaymentStep()" />
	</div>
</template>
<script>
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import {
	CREDIT_CARD_FORM_METHODS, ALLOWEDCC_BY_COUNTRY,
} from '@/settings/CreditCard';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE,
} from '@/settings/Errors';
import CreditCardFormV2 from '@/mixins/CreditCardFormV2';
import WindowSizes from '@/mixins/WindowSizes';
import Country from '@/util/Country';
import Cart from '@/util/Cart';
import CommonMix from '../../mixins/Common';
import IdentityV2 from '../../mixins/IdentityV2';
import Steps from '../../mixins/Steps';
import BitcoinPayment from '@/components/Payment/BitcoinPayment.vue';
import SezzlePayment from '@/components/Payment/SezzlePayment.vue';
import GCashPayment from '@/components/Payment/GCashPayment.vue';
import PayPalPayment from '@/components/Payment/PayPalPayment.vue';
import OxxoPayment from '@/components/Payment/OxxoPayment.vue';
import SameAddressAsCardStatementModal from '@/components/SameAddressAsCardStatementModal/index.vue';
import AddressCountryForm from '@/components/AddressCountryForm';
import EventBus from '@/util/eventBus';
import CreditCardPaymentRegisterV2 from '@/components/Payment/CreditCardPaymentRegisterV2.vue';

export default {
	name: 'PaymentEdit',
	components: {
		AddressCountryForm,
		SameAddressAsCardStatementModal,
		BitcoinPayment,
		SezzlePayment,
		OxxoPayment,
		PayPalPayment,
		GCashPayment,
		CreditCardPaymentRegisterV2,
	},
	mixins: [CommonMix, CreditCardFormV2, IdentityV2, Steps, WindowSizes],
	props: {
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		canSplitPayment: {
			type: Boolean,
			default: false,
		},
		cartTotal: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			paymentMethodData: new Cart(),
			selectedPaymentMethodName: 0,
			path: '',
			sameAs: false,
			sponsor: 0,
			identityId: null,
			uuid: null,
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
			},
			paymentForm: {},
			allowAnotherCountry,
			alert: new this.$Alert(),
			// companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			creditCardErrors: {},
			paymentToken: null,
			billingAddressError: false,
			validation: {},
			showSameAddressAsCardStatementModal: false,
			splitPayment: false,
			splitPaymentAgreement: false,
			splitAmount: null,
		};
	},
	computed: {
		selectedCardInfo() {
			// eslint-disable-next-line camelcase
			return this.validation?.card_data ?? {};
		},
		isCardPaymentMethod() {
			return CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName);
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		paymentMethods() {
			try {
				let { data } = this.paymentMethodData.data.response.data;
				data = data.filter((paymentMethod) => paymentMethod.attributes.method_code_name !== 'cashondelivery');
				return data;
			} catch (error) {
				return [];
			}
		},
		enabledPaymentMethods() {
			return Object.fromEntries(this.paymentMethods.map((paymentMethod) => {
				const codeName = paymentMethod.attributes.method_code_name;
				return [
					codeName,
					this.paymentMethodConditions[codeName]?.isAvailable ?? true,
				];
			}));
		},
		showSectionsAsCards() {
			return false;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
		conditions() {
			const conditions = { ...(this.paymentMethodConditions[this.selectedPaymentMethodName] || { isAvailable: true }) };
			if (Object.keys(conditions).length > 0 && typeof conditions.max !== 'undefined' && this.showSectionsAsCards) {
				conditions.isAvailable = this.remainingForOtherMethod <= conditions.max;
			}
			return conditions;
		},
	},
	watch: {
		selectedPaymentMethodName() {
			let timeout = 1000;
			if (this.isCardPaymentMethod) {
				timeout = 3000;
			}

			// TODO: Set the VGSCollect.create callback to get rid of this timeout
			// https://www.verygoodsecurity.com/docs/vgs-collect/js/integration

			// Waiting for LP Form
			this.preparing = true;
			setTimeout(() => {
				this.preparing = false;
			}, timeout);
		},
		sameAs: function sameAs(value) {
			if (value) {
				this.clearValidationError(true);
				this.billingAddressError = false;
				const { shipping } = this.getStepInformation('CheckoutShipping');
				Object.keys(shipping.shipping_address).forEach((property) => {
					if (property !== 'signature_required') this.form[property] = shipping.shipping_address[property];
				});
			} else {
				if (!this.billingAddressError) {
					Object.keys(this.form).forEach((key) => {
						this.form[key] = '';
					});
				}
				this.form.country_code = this.country;
			}
		},
	},
	mounted() {
		this.$emit('opened'); // This is under the assumption that the component is shown with a v-if
		this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry() }).then(() => {
			const cashIndex = this.paymentMethods.findIndex((x) => x.attributes.method_code_name === 'checkmo');
			if (cashIndex >= 0) {
				this.paymentMethods.splice(cashIndex, 1);
			}
			if (this.selectedPaymentMethodName === 0) {
				this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
			}
		});
	},
	created() {
		this.countryData.getCountries();
		this.getGeneralInformation();
		this.form.country_code = this.country;
		this.stateData.getStates(this.form.country_code);
		this.setStoredSameAs();
		this.getStoredInformation();
		return null;
	},
	methods: {
		handleContinue() {
			if (this.isCardPaymentMethod) {
				this.showSameAddressAsCardStatementModal = true;
			} else {
				this.savePaymentStep();
			}
		},
		savePaymentStep() {
			this.saveStep(this.validation);
		},
		setStoredSameAs() {
			const paymentInformation = this.getStepInformation('CheckoutPayment');
			if (paymentInformation.payment !== undefined) {
				const { same_as_shipping: sameAs } = { ...paymentInformation.payment.billing };
				if (sameAs === true) {
					const shippingInformation = this.getStepInformation('CheckoutShipping');
					const { shipping } = shippingInformation;
					Object.keys(shipping.shipping_address).forEach((key) => {
						this.form[key] = shipping.shipping_address[key];
					});
				}
				this.sameAs = sameAs;
			}
		},
		getGeneralInformation() {
			const shippingInformation = this.getStepInformation('CheckoutShipping');
			const {
				sponsor, identity_id: identityId, uuid, path,
			} = shippingInformation;
			try {
				const { shipping_address: shippingAddress } = shippingInformation.shipping;
				if (typeof shippingAddress === 'undefined' || typeof sponsor === 'undefined') {
					this.goToStep(1);
					return null;
				}
			} catch (error) {
				this.goToStep(1);
				return null;
			}

			this.sameAs = true;
			this.path = path;
			this.identityId = identityId;
			this.uuid = uuid;
			this.sponsor = sponsor;

			return null;
		},
		getStoredInformation() {
			const { payment } = { ...this.getStepInformation('CheckoutPayment') };
			if (typeof payment !== 'undefined' && typeof payment.payment_method !== 'undefined') {
				this.selectedPaymentMethodName = payment.payment_method.name;
				// Cash payments in this flow had been disabled by request from Corporate, by Nov. 2020
				// This case will never be applicable while cash payments are being filtered out from the payment methods endpoint response
				// if (this.path === 'guest' && this.selectedPaymentMethodName === 'checkmo') {
				// 	this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry() }).then(() => {
				// 		this.paymentMethods.splice(this.paymentMethods.findIndex((x) => x.attributes.method_code_name === 'checkmo'), 1);
				// 		this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
				// 	});
				// }
			}
			try {
				const { billing: data } = payment;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
					}
				});
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}
			} catch (error) {
				return null;
			}
			return null;
		},
		async prepareForStorage() {
			EventBus.$emit('callAddressRequestData');
			this.preparing = true;

			const paymentMethod = { name: this.selectedPaymentMethodName };

			if (this.isCardPaymentMethod) {
				// Get Lacore token or tokens
				await this.$refs.creditCardV2Register.lacoreV2HandleResponse();

				// Use single payment
				if (!this.$refs.creditCardV2Register?.splitPayment) {
					paymentMethod.token = this.$refs.creditCardV2Register.lacoreV2PaymentToken;
				}

				// Set multiple credit card tokens and amounts if selecting split payments
				if (this.$refs.creditCardV2Register?.splitPayment) {
					paymentMethod.split_amount = this.$refs.creditCardV2Register.splitPaymentAmount;
					this.$refs.creditCardV2Register.lacoreV2PaymentTokens.forEach((token, index) => {
						// Generate and fill token parameter keys as such:
						// 	payment.token
						// 	payment.token_2
						// 	payment.token_3
						// 	...
						const tokenKeySuffix = index > 0 ? `_${index + 1}` : '';
						const tokenKey = `token${tokenKeySuffix}`;
						paymentMethod[tokenKey] = token;
					});
				}

				// eslint-disable-next-line no-underscore-dangle
				paymentMethod.sift_session_id = window._sift_session_id;

				// Create v2 identity if using CCv2.
				// It is created here instead of in Personal Information because we must use a shipping address
				try {
					await this.lacoreV2CreateIdentity();
				} catch (error) {
					// Stop validation if there was a problem creating identity
					this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
					this.preparing = false;
					return null;
				}
			} else if (['oxxopay', 'sezzle', 'lptpaypal'].includes(this.selectedPaymentMethodName)) {
				// eslint-disable-next-line no-underscore-dangle
				paymentMethod.sift_session_id = window._sift_session_id;
			}

			this.validation = {
				step: this.currentValidation,
				sponsor: this.sponsor,
				identity_id: this.identityId,
				uuid: this.uuid,
				path: this.path,
				payment: {
					billing: {
						...this.form,
						same_as_shipping: this.sameAs,
					},
					payment_method: paymentMethod,
				},
			};

			this.validateStep(this.validation).then((response) => {
				this.validation = { ...this.validation, card_data: { ...response.response } };
				if (!this.lacoreV2Failed) {
					this.handleContinue();
				}
			}).catch((error) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					if (typeof this.validationErrors.cart_id !== 'undefined') {
						let response = '';
						this.validationErrors.cart_id.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('cartValidationError');
						}, 6000);
					}
					if (typeof this.validationErrors.identity_id !== 'undefined') {
						this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
					}

					// Single CC payment and Split CC payment #1 errors
					if (
						typeof this.validationErrors[`${this.currentValidation}.payment_method.token`] !== 'undefined'
						&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
						&& !this.$refs.creditCardV2Register.lacoreV2Failed
					) {
						this.$refs.creditCardV2Register.lacoreV2CreditCardErrors.number.errorMessages = this.validationErrors[`${this.currentValidation}.payment_method.token`];
					}

					// Split CC payment #2 errors
					if (
						typeof this.validationErrors[`${this.currentValidation}.payment_method.token_2`] !== 'undefined'
						&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
						&& !this.$refs.creditCardV2Register.lacoreV2Failed
					) {
						this.$refs.creditCardV2Register.lacoreV2CreditCard2Errors.number.errorMessages = this.validationErrors[`${this.currentValidation}.payment_method.token_2`];
					}

					// CC v2 payment errors
					if (
						typeof this.validationErrors[`${this.currentValidation}.payment_method.token`] !== 'undefined'
						&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
						&& !this.$refs.creditCardV2Register.lacoreV2Failed
					) {
						this.$refs.creditCardV2Register.lacoreV2CreditCardErrors.number.errorMessages = this.validationErrors[`${this.currentValidation}.payment_method.token`];
					}

					const errorKeys = Object.keys(this.validationErrors);
					const match = errorKeys.filter((errorKey) => errorKey.search('billing') > 0);
					this.billingAddressError = match.length > 0;
					if (this.billingAddressError) {
						this.sameAs = false;
					}
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		creditCardHasErrors(field) {
			if (typeof this.creditCardErrors[field] !== 'undefined' && this.creditCardErrors[field].errorMessages.length > 0) {
				return true;
			}
			return false;
		},
	},
};
</script>
